import { lazy } from "react";
import Loadable from "shared/Loadable";
import AuthGuard from "components/AuthGuard";

const MainLayout = Loadable(lazy(() => import("layouts/LandingPageLayout")));
const LandingPage = Loadable(lazy(() => import("./pages/LandingPage")));
const Pricing = Loadable(lazy(() => import("./pages/Pricing")));
const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
const SearchHistory = Loadable(lazy(() => import("./pages/SearchHistory")));
const Profile = Loadable(lazy(() => import("./pages/Profile")));
const Flowers = Loadable(lazy(() => import("./pages/Flowers")));
const FlowersBulkUpload = Loadable(lazy(() => import("./pages/Flowers/Pages/FlowersBulkUpload")));
const AddFlowerPage = Loadable(lazy(() => import("./pages/Flowers/Pages/AddFlowerPage")));
const MedicalUserSample = Loadable(lazy(() => import("./pages/Sample/MedicalUsers")));
const RecreationalUserSample = Loadable(lazy(() => import("./pages/Sample/RecreationalUsers")));
const MedicalUserResults = Loadable(lazy(() => import("./pages/Results/MedicalUser")));
const RecreationalUserResults = Loadable(lazy(() => import("./pages/Results/RecreationalUser")));
const PaymentSuccess = Loadable(lazy(() => import("./pages/Payment/PaymentSuccess")));
const PaymentFail = Loadable(lazy(() => import("./pages/Payment/PaymentFail")));
const ResetPassword = Loadable(lazy(() => import("./components/auth/ResetPassword")));
const TrailPeriod = Loadable(lazy(() => import("pages/TrailPeriod/TrailPeriod")));

const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [
    {
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/medical", element: <MedicalUserSample /> },
        { path: "/recreational/:id?", element: <RecreationalUserSample /> },
        { path: "/payment_success", element: <PaymentSuccess /> },
        { path: "/payment_fail", element: <PaymentFail /> },
        { path: "/reset-password", element: <ResetPassword /> },

        {
          element: <AuthGuard />,
          children: [
            { path: "search-history", element: <SearchHistory /> },
            { path: "profile", element: <Profile /> },
            { path: "flowers-management", element: <Flowers /> },
            { path: "flowers-management/bulk-upload", element: <FlowersBulkUpload /> },
            { path: "/flowers-management/flower/:id?", element: <AddFlowerPage /> },
            { path: "medical-results", element: <MedicalUserResults /> },
            { path: "recreational-results", element: <RecreationalUserResults /> },
            { path: "pricing", element: <Pricing /> },
            { path: "trial-period", element: <TrailPeriod /> },
            { path: "checkout", element: <Checkout /> }
          ]
        }
      ]
    },
    { path: "*", element: <Error /> }
  ];
};

export default routes;
