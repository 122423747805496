import axios from "axios";
import { t } from "i18next";
import { getState } from "reducers/configureStore";
import { errorToast } from "shared/Toast";

export const ArrayFrom = (size) => Array.from(Array(size).keys());

export const UserType = {
  SUPER_ADMIN: 1,
  STANDARD_USER: 2,
  BUSINESS_ADMIN: 3,
  SELLER: 4
};

export const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push("Something went wrong!");
  }
  errorArray.forEach((errorMessage, index) => {
    errorToast(errorMessage);
  });
};

export const catchErrorHandler = (error, title) => {
  if (!axios.isCancel(error)) {
    console.log(`error ${title ?? ""}`, error);
  }
};

export const downloadBlobData = (data, fileName) => {
  try {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    // Clean up after the download
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    catchErrorHandler(error);
  }
};

export const authResponseHandler = (responseData) => {
  const checkTrialExpired = (trialExpiresAt) => {
    const currentDate = new Date();
    const trialExpireDate = new Date(trialExpiresAt);
    return currentDate > trialExpireDate;
  };

  return {
    ...responseData,
    id: responseData.id,
    firstName: responseData.first_name ?? "",
    lastName: responseData.last_name ?? "",
    email: responseData.email ?? "",
    phone: responseData.phone_number ?? "",
    dob: responseData.date_of_birth ?? "",
    city: responseData.city
      ? {
          id: responseData.city.id,
          label: responseData.city.name
        }
      : null,
    country: responseData.country
      ? {
          id: responseData.country.id,
          label: responseData.country.name,
          shortCode: responseData.country.iso3
        }
      : null,
    state: responseData.state
      ? {
          id: responseData.state.id,
          label: responseData.state.name
        }
      : null,
    postalCode: responseData.postal_code ?? "",
    paymentStatus: responseData.payment_status,
    isTrialExpired: checkTrialExpired(responseData.trial_expires_at)
  };
};

export const formateHistoryData = (historyData) => {
  const allIllnesses = getState().MasterData.illnessData;
  let illness = JSON.parse(historyData.illness);
  if (illness.length) {
    illness = illness.map((id) => {
      const illness = allIllnesses?.find((a) => a.id === id);
      return illness;
    });
  }
  return {
    cbdLevels: historyData.cbd_levels,
    illnesses: illness,
    aromatics: JSON.parse(historyData.aromatic_ids),
    consumeTime: JSON.parse(historyData.best_time_to_consume),
    id: historyData.id
  };
};

export const formatMedicalHistoryData = (medicalData) => {
  const allIllnesses = getState().MasterData.illnessData;
  const illnessIds = JSON.parse(medicalData.illness);
  const expertNames = JSON.parse(medicalData.expert_names);

  let illnesses = [];
  if (illnessIds.length) {
    illnesses = illnessIds
      .map((id) => {
        const illness = allIllnesses?.find((a) => a.id === id);
        return illness
          ? {
              ...illness,
              cannabinoids: JSON.parse(illness.cannabinoids || "[]"),
              consumer_experience: JSON.parse(illness.consumer_experience || "[]"),
              genetics: JSON.parse(illness.genetics || "[]"),
              min_thc_levels: illness.min_thc_levels,
              max_thc_levels: illness.max_thc_levels,
              status: illness.status,
              status_updated_by: illness.status_updated_by
            }
          : null;
      })
      .filter(Boolean);
  }
  return {
    id: medicalData.id,
    best_time_to_consume: medicalData.best_time_to_consume,
    consumer_experience: medicalData.consumer_experience,
    insured: medicalData.insured,
    expert_names: expertNames,
    join_club: medicalData.join_club,
    cbd_levels: medicalData.cbd_levels,
    illnesses,
    aromatic_ids: medicalData.aromatic_ids,
    user_id: medicalData.user_id,
    is_bookmarked: medicalData.is_bookmarked,
    created_at: medicalData.created_at,
    updated_at: medicalData.updated_at
  };
};

export const getMedicalDataById = async (medicalHistoryId, signal) => {
  if (!medicalHistoryId) return;
  try {
    const { data } = await axios.get(`/api/medical_histories/${medicalHistoryId}`, { signal });

    if (data?.status) {
      return formatMedicalHistoryData(data.data);
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    catchErrorHandler(error, "getMedicalDataById");
  }
};

const formatRecreationHistoryData = (data) => ({
  cultivationTypes: JSON.parse(data.cultivation_type),
  thcRange: data.thc_range,
  cbd: data.cbd,
  floweringTime: data.flowering_time,
  yieldRange: data.yield_range,
  growingExperience: JSON.parse(data.growing_experience),
  flowerHeightRange: data.flower_height_range,
  flowerTraits: JSON.parse(data.flower_traits),
  gender: data.gender,
  spaceRange: data.space_range,
  isBookmarked: !!data.is_bookmarked,
  userDetails: JSON.parse(data.user_details),
  aromaticIds: JSON.parse(data.aromatic_ids),
  terpeneIds: JSON.parse(data.terpene_ids),
  effectIds: JSON.parse(data.effect_ids)
});

export const getRecreationalDataById = async (recreationHistoryId, signal) => {
  if (!recreationHistoryId) return;
  try {
    const { data } = await axios.get(`/api/recreation_histories/${recreationHistoryId}`, { signal });

    if (data?.status) {
      return formatRecreationHistoryData(data.data);
    } else {
      errorMessageHandler(data);
    }
  } catch (error) {
    catchErrorHandler(error, "getRecreationalDataById");
  }
};

export const getRole = (responseData) => {
  if (!responseData || !responseData.type) {
    return null;
  }
  const updatedType =
    responseData?.type === UserType.SUPER_ADMIN
      ? t("personalInfo.superAdmin")
      : responseData.type === UserType.STANDARD_USER
        ? t("personalInfo.standardUser")
        : responseData.type === UserType.BUSINESS_ADMIN
          ? t("personalInfo.businessUser")
          : responseData.type === UserType.SELLER
            ? t("personalInfo.seller")
            : null;
  return updatedType;
};
